// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
@media (prefers-color-scheme: primary) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #afccff;
    --ion-color-primary-rgb: 175, 204, 255;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #9ab4e0;
    --ion-color-primary-tint: #b7d1ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
// http://ionicframework.com/docs/theming/


// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

body {
  padding-top: env(safe-area-inset-top);
}

ion-footer {
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

.add_pc_bounds {
  /* padding: 30px; */
  position: absolute;
  top: 100px;
  left: 50%;
  /* margin-top: -310px; */
  /* margin-left: -175px; */
  width: 352px;
  height: 620px;
  //border-radius: 5%;
}

.add_pc_bounds_modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

//.iphonebg {
//  background-image: url('../assets/images/iphonebg_new.png');
//  background-repeat: no-repeat;
//  background-size: contain;
//  width: 470px;
//  height: 830px;
//  position: absolute;
//  top: 50%;
//  left: 50%;
//  margin-top: -375px;
//  margin-left: -207.5px;
//  /* padding: 50px; */
//}

.iphonebg {
  background-image: url('../assets/images/iphonebg-removebg.png');
  background-repeat: no-repeat;
  width: 415px;
  height: 815px;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -407.5px;
  margin-left: -207.5px;
  /* padding: 50px; */
}

.modal-wrapper {
  left: 0;
  /*max-width: 370px !important;*/
}

// @font-face {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 100;
//   src: local("Roboto Thin"), local("Roboto-Thin"), url("#{$roboto-font-path}/roboto-thin.woff2") format("woff2"), url("#{$roboto-font-path}/roboto-thin.woff") format("woff"), url("#{$roboto-font-path}/roboto-thin.ttf") format("truetype");
// }

.declined_media {
  .toast-wrapper {
    background-color: #960414;
    text-align: center;

    .toast-button {
      background-color: #fff;
      color: #960414;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

ion-badge {
  float: right;
}

.posts-count {
  display: inline-block;
  text-align: left;
}

.flex {
  display: flex;
}

.border-red {
  border-color: #f30d0d !important;
}

.alert-error {
  padding: 0 40px 10px;
  text-align: center;
  color: red;
}

.item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus):not(.item-input-has-focus) .item-inner {
  border-bottom-color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.item-md.item-input.item-input-has-focus .item-inner,
.item-md.item-input.input-has-focus .item-inner {
  border-bottom-color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
}


.striked-header {
  display: flex;
  width: 80%;
  margin: 20px auto;
}

.striked-border {
  border: solid 0.5px;
  height: 1px;
  margin-top: 0.5em;
}

.striked-header div {
  flex: 3;
  text-align: center;
  color: #fff;
}

.striked-text {
  flex: 1 !important;
}

.show-password {
  color: var(--text-primary);
  align-self: flex-end;
}

.show-password ion-icon {
  font-size: 2em;
  bottom: 0;
  position: relative;
}

/* CUSTOMIZATIONS */
ion-header {
  background: var(--primary);
  ion-title {
    color: black;
    font-weight: 400;
  }
  ion-toolbar {
    --background: var(--primary);
  }
}

.datetime-md {
  padding: 9px;
}

span.button-inner {
  font-weight: 300;
}

button.btn-back-custom {
  background: none;
  box-shadow: none;
  font-size: 2em;
  padding: 0;
  position: absolute;
}

.text-thin {
  font-weight: 100;
}

.icon-info {
  background-image: url("../assets/svg/info.svg");
  float: right;
  height: 20px;
  width: 20px;
  background-size: 20px;
  margin-right: 15px;
  filter: invert(100%);
}

.icon-post {
  background-image: url("../assets/svg/new_post.svg");
}

.icon-video-post {
  background-image: url("../assets/svg/new_post_video.svg");
}

.icon-story {
  background-image: url("../assets/svg/new_story.svg");
}

.icon-video-story {
  background-image: url("../assets/svg/new_story_video.svg");
}

.icon-offers {
  background-image: url("../assets/svg/offers.svg");
  height: 27px;
  width: 27px;
  background-size: cover;
  display: block;
  margin: 7px auto 4px auto;
}

.icon-payments {
  background-image: url("../assets/svg/payments.svg");
  height: 27px;
  width: 27px;
  background-size: cover;
  display: block;
  margin: 7px auto 4px auto;
  filter: invert(100%);
}

.icon-profile {
  background-image: url("../assets/svg/profile.svg");
  height: 27px;
  width: 27px;
  background-size: cover;
  display: block;
  margin: 7px auto 4px auto;
}

.icon-check {
  background-image: url("../assets/svg/v.svg");
}

.icon-upload {
  height: 48px;
  width: 85%;
  background-size: 35px 35px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}

.icon-archive {
  background-image: url("../assets/svg/archive.svg");
  height: 27px;
  width: 27px;
  background-size: cover;
  display: block;
  margin: 7px auto 4px auto;
}

ion-footer {
  height: calc(70px + env(safe-area-inset-bottom));
  background-color: var(--primary);
}

.badge-notification {
  position: absolute;
  top: 7px;
  right: 26px;
  padding: 3px 6px;
  border-radius: 50%;
  background-color: #f30d0d;
  color: white;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  animation: pulse 2s infinite;
}

.change-language {
  position: absolute;
  top: calc(16px + env(safe-area-inset-top));
  width: 60px;
  color: #FFFFFF;
  padding: 2px 5px;
  text-align: right;
  font-weight: 400;
  text-transform: uppercase;
  background-color: var(--primary);
  z-index: 999998;

  .select-icon-inner {
    color: #FFFFFF !important;
    left: 2px !important;
  }

  .select-text {
    margin-left: 15px;
  }
}

.globe-icon {
  position: absolute;
  z-index: 999999;
  top: calc(19px + env(safe-area-inset-top));
  font-weight: 100;
  color: #FFFFFF;
  left: 19px;
  right: auto;
}

ion-icon[name="arrow-back"] {
  font-size: 1.5rem;
  color: #ffffff;
}

.custom-splash {
  position: absolute;
  background: var(--primary);
  display: flex;
  z-index: 1;
  width: 415px;
  height: 815px;
  top: 50%;
  left: 50%;
  margin-top: -407.5px;
  margin-left: -207.5px;
  padding: 60px;

}

.iphonebg .custom-splash {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
}

@keyframes a {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

.loader-icon {
  position: absolute;
  bottom: 20%;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  height: 50px;
}

.circle {
  width: 100%;
  height: 100%;
  border: solid 3px #ffffff;
  border-radius: 50%;
  animation: a 2s infinite linear;
  box-sizing: border-box;
}

.dial {
  width: 4px;
  height: 38px;
  background: #ffffff;
  position: absolute;
  left: 50%;
  top: 3px;
  margin-left: -2px;
}

.rtl {
  ion-item, input, .details, .clear, ion-label, ion-alert, ol, .code_verification, app-pending-approval, .ion-text-right, .ion-text-center, .ion-text-left, .error, .account-status, .instructions {
    direction: rtl;
  }

  .ion-custom-item {
    padding-right: 8px !important;
  }

  gallery-modal .close-button {
    left: auto !important;
    right: 5px;
  }

  ion-item:not(.list-campaigns) {
    h2, p {
      margin-left: 0;
      margin-right: 105px;

    }
  }

  .list-campaigns {
  }

  ion-badge, .post-image-holder, .img-icon {
    float: left;
  }

  .post-declined.no-match {
    margin-left: 100px;
  }

  app-campaign .post-card h3 {
    float: right;
  }


  ion-card {
    &.start {
      border-left: none !important;
      border-right: 4px solid #FF687E;
    }

    &.approved {
      border-left: none !important;
      border-right: 4px solid #19a1b9;
    }

    &.complete {
      border-left: none !important;
      border-right: 4px solid #2aa646;
    }

    &.declined {
      border-left: none !important;
      border-right: 4px solid #f30d0d;
    }

    &.review {
      border-left: none !important;
      border-right: 4px solid #d29e04;
    }
  }

  .ion-md-arrow-back:before {
    content: "\f287";
  }

  ion-label[position="floating"] {
    transform-origin: right top;
  }

  .change-language .select-icon {
    position: initial;
  }

  phone, .interests, .pac-container, .change-language, .globe-icon, .swiper-wrapper, .radio {
    direction: ltr;
  }

  .radio ion-label {
    direction: rtl;
  }

  .globe-icon, .change-language {
    left: auto;
    right: 10px;
  }

  app-signin img.icon {
    margin-left: 10px;
  }

  .posts-count {
    text-align: right;
    margin-left: 5px;
  }

  .flag {
    float: right;
    margin-right: 0;
  }

  .ig-tags {
    float: right;
    direction: ltr;
  }

  .error {
    margin-right: 15px;
  }

}
input {
  -webkit-transform: translateZ(0px);
}

.muted {
  color: red;
}

// swiper
.swiper-pagination-bullet-active {
  background: var(--beige);
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(#f30d0d, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(#f30d0d, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(#f30d0d, 0);
  }
}

ion-content::-webkit-scrollbar {
  display: none;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(#f30d0d, 0.4);
    box-shadow: 0 0 0 0 rgba(#f30d0d, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(#f30d0d, 0);
    box-shadow: 0 0 0 10px rgba(#f30d0d, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(#f30d0d, 0);
    box-shadow: 0 0 0 0 rgba(#f30d0d, 0);
  }
}

ion-header {
  ion-toolbar {
    padding: 0 16px;
    width: 200px;
    ion-icon {
      font-size: 22px;
      color: var(--ion-color-primary);
      cursor: pointer;
    }
    ion-title {
      font-size: 10px;
      font-weight: 500;
      color: var(--ion-color-dark);
    }
  }
}

.profile-list {
  width: 100%;
  ion-item.bordered {
    border: 1px solid var(--ion-color-light);
    border-radius: 5px;
    background-color: var(--ion-color-light);
    margin-bottom: 10px;

    ion-label {
      font-size: 14px;
      color: var(--ion-color-medium);
      font-weight: 500;
    }

    ion-input {
      font-size: 15px;
      color: var(--ion-color-dark);
    }
  }
}

.error {
  font-size: 13px;
  color: var(--ion-color-danger);
  margin-left: 20px;
}

.interests {
  margin-top: 20px;
  .icon-set {
    display: flex;
    justify-content: space-around;

    .icon {
      width: 40px;
      height: 40px;
      background-color: var(--ion-color-light);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: var(--ion-color-medium);
      transition: background-color 0.2s ease;

      &.icon-selected {
        background-color: var(--ion-color-primary);
        color: white;
      }
    }
  }
}

.bottom-tabs {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid var(--text-muted);
  padding: 8px 8px 8px 8px;

  button {
    border: none;
    background-color: transparent;
    color: black;
    font-weight: 300;
    flex: 1;
    padding: 10px 20px; // Increase padding for a larger tap area
    text-align: center;
    transition: all 0.3s ease; // Smooth transition for the pill style

    &:not(.selected-tab) {
      color: var(--accent); // Use muted color for unselected tabs
    }

    &.selected-tab {
      background-color: var(--accent);
      color: white;
      font-weight: 500;
      border-radius: 20px; // Add rounded corners for the pill effect
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); // Optional: add shadow to highlight active tab
    }

    .badge-notification {
      position: absolute;
      top: 4px;
      right: 12px;
      background: var(--ion-color-danger);
      color: white;
      border-radius: 50%;
      padding: 3px 5px;
      font-size: 10px;
    }
  }
}

